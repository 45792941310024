import React from 'react';
import { Color } from '../../../40-utils/color/Color';
import { useSkin } from '../../../40-utils/react-hooks/useSkin';
import { ISkin } from '../../../50-systems/StyleSystem/ISkin';
import { consolexBase } from '../../../consolex';
import { styled } from '../../../libraries';
import { ILoaderProps } from './ILoaderProps';
import { useTimeout } from './utils/useTimeout';

interface ILoaderBlockProps extends ILoaderProps {
    /**
     * Is the gray background around the loader
     */
    isWithBackground?: true;
}

/**
 * @collboard-modules-sdk
 */
export function Loader({ alt, canLoadForever, isWithBackground }: ILoaderBlockProps) {
    const { skin } = useSkin();

    useTimeout({ duration: canLoadForever ? Infinity : 3000, alt });

    return (
        <LoaderDiv
            onClick={() => consolexBase.group(`🔄 Loading of "${alt}"`).trace().end()}
            {...{ skin, isWithBackground }}
        >
            {/* TODO: Please @roseckyj  loader that would cover only its area not the whole modal, */}
            {/* TODO: Please @roseckyj make it universal to use it in every layout type - small icon, fullscreen, modal, etc,... */}
            {/* TODO: Please @roseckyj untite with LoaderInline */}
            <div className="spinner"></div>
        </LoaderDiv>
    );
}

const LoaderDiv = styled.div<{ skin: ISkin; isWithBackground?: boolean }>`
    z-index: 9999;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;

    ${({ isWithBackground, skin }) =>
        !isWithBackground ? `` : `background: ${Color.from(skin.colors.black).withAlpha(0.4).toString()};`};

    .spinner {
        height: 100%;
        width: 15%;
        max-width: 200px;
        aspect-ratio: 1 / 1;
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.45/${
            ({ isWithBackground }) => (isWithBackground ? 'loading.svg' : 'loading-gray.svg') /* */
        }');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        animation-name: spinning;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }
`;

/**
 * TODO: [☮️] Implement new loading /handwritten/ design
 * TODO: [🍳] Maybe rename Loader to LoaderBlock
 */

import { Registration } from 'destroyable';
import React from 'react';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../../50-systems/ToolbarSystem/0-ToolbarSystem';
import { DiceArt } from './DiceArtModule';

/**
 *
 * Note: In future this file will we in independent repository as external module.
 *
 */

internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: 'DiceTool',
            title: { en: 'Dice', cs: 'Kostka' },
            description: {
                en: 'Generate random numbers for you and others on the board',
                cs: 'Hoďte si kostkou a získejte náhodné číslo',
            },
            categories: ['Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.45/icons/dice.svg',

            author: Authors.rosecky,
        },
        toolbar: ToolbarName.Tools,
        async icon(systems) {
            const { attributesSystem } = await systems.request('attributesSystem');
            return {
                name: 'dice',
                order: 61,
                icon: 'dice' /* [🎄] icon: new URL(`https://avatars.githubusercontent.com/u/23721952?s=48&v=4`), */,
                boardCursor: 'default',
                menu: <>{attributesSystem.inputRender('diceSides')}</>,
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, attributesSystem, materialArtVersioningSystem } =
                    await systems.request(
                        'touchController',
                        'collSpace',
                        'attributesSystem',
                        'materialArtVersioningSystem',
                    );

                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe(async (touch) => {
                        const boardPosition = collSpace.pickPoint(touch.firstFrame.position).point;

                        const newArt = new DiceArt(
                            boardPosition,
                            attributesSystem.getAttributeValue('diceSides').value as number,
                        );

                        const operation = materialArtVersioningSystem.createPrimaryOperation();
                        operation.newArts(newArt);
                        operation.persist();
                    }),
                );
            },
        },
    }),
);

/**
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 */

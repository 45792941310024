import styled from 'styled-components';
import { ISkin } from '../../../../../50-systems/StyleSystem/ISkin';

export const ModuleStoreStyle = styled.div<{ skin: ISkin }>`
    .search {
        width: 100%;
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.45/icons/search@2x.png');
        background-position: right 10px center;
        background-size: 20px;
        background-repeat: no-repeat;
        padding-right: 35px;
    }

    .servicing {
        opacity: 0.3;
    }
`;

/**
 * TODO: [🏴‍☠️] Across the projects components+files with styled name call WelcomeModalStyle (not WelcomeModalDiv)
 */
